<!-- Embedded youtube component that preloads an image and loads the youtube iframe on click -->
<template>
  <div :class="classes" @click="playVideo">
    <div v-if="showPlaceholder" class="position-relative d-flex align-items-center justify-content-center">
      <i class="bi bi-play-btn-fill overlayed-play-icon"></i>
      <img class   = "youtube-placeholder-image rounded-4"
           alt     = "Youtube placeholder image"
           :src    = "placeholder"
           :width  = "width"
           :height = "height" />
    </div>
    <div v-else  class="ratio ratio-16x9">
      <iframe class       = "rounded-4"
              :width      = "width"
              :src        = "autoplaySource"
              frameborder = "0"
              allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // Additional CSS classes to add to this component.
      cssClass:       { default: '' },
      // Height of the component (default: null).
      height:         { default: null },
      // Placeholder source image.
      placeholderSrc: { default: null },
      // Thumbnail, medium, high, max (default: 'high').
      src:            { default: null },
      // Thumbnail, medium, max (default: 'max').
      quality:        { default: 'high' },
      // Width of the component (default: null).
      width:          { default: null }
    },

    data() {
      return {
        // Public: Which resolution image to fetch from YouTube.
        //         (default: high) .. max not available currently
        qualityMapping: {
          thumbnail: 'default.jpg',
          medium:    'mqdefault.jpg',
          high:      'hqdefault.jpg',
          max:       'maxresdefault.jpg'
        },
        // Public: Whether or not the placeholder should be displayed (default: true).
        showPlaceholder: true,
        // Internal: CSS classes for video.
        videoClass:      ''
      }
    },

    computed: {
      // Public: The source youtube URL with autoplay enabled.
      //
      // 12/6/24 autoplay option currently deprecated
      //
      // Returns a string.
      autoplaySource() {
        return `${this.src}?autoplay=1&enablejsapi=1`
      },

      // Internal: Build the CSS classes to apply to this component
      classes() {
        return `youtube-aspect-ratio-controller ${this.cssClass}${this.videoClass}`
      },

      // Public: Placeholder image URL.
      //
      // Returns a string.
      placeholder() {
        const qualityImg = this.qualityMapping[this.quality]
        const youtubePlaceholder = `https://img.youtube.com/vi/${this.videoId}/${qualityImg}`

        return this.placeholderSrc || youtubePlaceholder
      },

      // Public: Extract the youtube video id from the source URL.
      //
      // Returns a string.
      videoId() {
        const match = this.src.match(/\/embed\/([a-zA-Z0-9_-]+)/)

        return match[1]
      }
    },

    methods: {
      // Public: Enable video by hiding the placeholder and showing the youtube component.
      playVideo() {
        this.showPlaceholder = false
        this.videoClass      = ' video-aspect-ratio-container'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .overlayed-play-icon {
    position: absolute;
    color: rgba(222, 222, 222, .7);
    font-size: 5.9rem;
    cursor: pointer;
  }

  .youtube-aspect-ratio-controller {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .youtube-placeholder-image {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
    cursor: pointer;
  }
</style>
